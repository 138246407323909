<template>
  <v-select
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :value="personelSelect"
    :options="selectionPersonelList"
    class="w-100"
    :reduce="val => val.value"
    @input="(val) => $emit('update:personelSelect', val)" />
</template>

<script>
export default {
  props: {
    personelSelect: {
      type: [Number, null],
      default: null,
    },
  },
  computed: {
    selectionPersonelList() {
      return this.$store.getters.getPersonels;
    },
  },
  methods: {
    fetchPersonels() {
      this.$store.dispatch('fetchPersonels')
    },
  },
  created() {
    this.fetchPersonels();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
