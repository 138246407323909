<template>
  <b-card>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <b-form-group :label="$t('Oluşturma Tarihi Başlangıç')" label-for="dtOlusTar">
            <b-form-input type="date" v-model="dtBaslangicFilter" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group :label="$t('Oluşturma Tarihi Bitiş')" label-for="dtOlusTar">
            <b-form-input type="date" v-model="dtBitisFilter" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Müşteri") }}</label>
          <musteri-selection :musteri-select.sync="musteriIdFilter" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Personel") }}</label>
          <personel-selection :personel-select.sync="personelIdFilter" />
        </b-col>
      </b-row>
  </b-card>
</template>

<script>
import MusteriSelection from '../selection/MusteriSelection.vue'
import PersonelSelection from '../selection/PersonelSelection.vue'

export default {
  components: {
    MusteriSelection,
    PersonelSelection,
  },
  computed: {
    dtBaslangicFilter: {
      get() {
        return this.$store.getters['odemeYonetim/getPaymentFilter'].dtBaslangic
      },
      set(value) {
        this.$store.commit('odemeYonetim/setPaymentFilter', { key: 'dtBaslangic', value })
      },
    },
    dtBitisFilter: {
      get() {
        return this.$store.getters['odemeYonetim/getPaymentFilter'].dtBitis
      },
      set(value) {
        this.$store.commit('odemeYonetim/setPaymentFilter', { key: 'dtBitis', value })
      },
    },

    musteriIdFilter: {
      get() {
        return this.$store.getters['odemeYonetim/getPaymentFilter'].musteriId
      },
      set(value) {
        this.$store.commit('odemeYonetim/setPaymentFilter', { key: 'musteriId', value })
      },
    },

    personelIdFilter: {
      get() {
        return this.$store.getters['odemeYonetim/getPaymentFilter'].personelId
      },
      set(value) {
        this.$store.commit('odemeYonetim/setPaymentFilter', { key: 'personelId', value })
      },
    },
  },
}
</script>
