<template>
  <div>
    <crud-button :showAddNew="false" :onClickList="fetchPayments" />
    <odeme-filters />

    <b-card no-body class="p-2">
      <DxDataGrid
        id="gridContainer"
        :height="600"
        :data-source="payments"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :selection="{ mode: 'single' }"
        :hover-state-enabled="true"
        :column-auto-width="true"
        key-expr="id"
      >
        <DxFilterRow :visible="true" apply-filter="auto" />
        <DxHeaderFilter :visible="true" />
        <DxPaging :enabled="false" />
        <DxSearchPanel
          :visible="true"
          :width="240"
          :placeholder="$t('Ara...')"
        />
        <DxColumn data-field="id" caption="Id" :width="50" />
        <DxColumn
          data-field="isSuccessOdeme"
          cell-template="isSuccessOdemeTemplate"
          caption="Durum"
          :width="100"
        />
        <DxColumn
          :width="200"
          data-field="dtOdemeTarih"
          caption="Ödeme Tarih"
          alignment="right"
          data-type="date"
          sort-order="asc"
        />
        <DxColumn data-field="sOdemeNo" caption="Ödeme No" />
        <DxColumn data-field="musteriAdi" caption="Müşteri Adı" />
        <DxColumn data-field="kullaniciAdi" caption="Kullanıcı Adı" />
        <DxColumn
          data-field="ldOdemeTutari"
          caption="Ödeme Tutarı"
          alignment="right"
          :customize-text="customizeDecimalDxGrid"
        />
        <DxSummary>
          <DxTotalItem
            column="ldOdemeTutari"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
        </DxSummary>
        <template #isSuccessOdemeTemplate="{ data }">
          <div>
            <b-badge variant="success" v-show="data.value">{{ $t("Başarılı") }}</b-badge>
            <b-badge variant="danger" v-show="!data.value">{{ $t("Başarısız") }}</b-badge>
          </div>
        </template>
      </DxDataGrid>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { customizeDecimalDxGrid } from '@core/utils/filter';
import { locale } from 'devextreme/localization';
import { DxDataGrid, DxColumn, DxFilterRow, DxSearchPanel, DxSummary, DxPaging, DxTotalItem, DxHeaderFilter } from 'devextreme-vue/data-grid';
import CrudButton from '@/components/CrudButton.vue';
import OdemeFilters from './OdemeFilters.vue';

export default {
  components: {
    CrudButton,
    OdemeFilters,
    DxPaging,
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxSearchPanel,
    DxSummary,
    DxTotalItem,
    DxHeaderFilter,
  },

  methods: {
    fetchPayments: mapActions(['odemeYonetim/fetchPayments'])['odemeYonetim/fetchPayments'],
    customizeDecimalDxGrid,
  },

  computed: {
    payments: mapGetters(['odemeYonetim/getPayments'])['odemeYonetim/getPayments'],
  },

  mounted() {
    this.fetchPayments();
  },
  created() {
    locale('tr')
  },
}
</script>
